.form-group.required .form-control-label::before {
  content: "*";
  color: #c00;
}

.login-page {
  margin-top: 1em;
}
.login-page .login-form-nav {
  margin-bottom: 1.25em;
}
.login-page .login-form-nav .tab-pane {
  margin-top: 1em;
}
.login-page .login-form-nav .nav-link {
  color: #000;
}
.login-page .login {
  margin-bottom: 0.938em;
}
.login-page .login .error-message-form {
  padding: 0.625em;
  background-color: #c00;
  color: #fff;
  border-radius: 0.188em;
}

.registration-page .privacy-policy {
  margin-top: 1rem;
}

.login-banner {
  background-image: url("../images/account.jpg");
  background-position-y: 40%;
}

.equal-height .card {
  width: 100%;
}

.track-order-header {
  font-size: 1.75rem;
}
@media (max-width: 543.98px) {
  .track-order-header {
    margin-top: 30px;
    margin-left: -15px;
  }
}

.request-password-title {
  font-size: 1.5rem;
}

/* $picton-blue: #3190e8;
$curious-blue: #2780dc; */
:root {
  --primaryColor: #333;
}

h1.page-title {
  color: #eb254b;
  font-size: 30px;
  background: #fff;
  font-family: "OpenSans-Light", arial, sans-serif;
  left: unset;
  overflow: hidden;
}
h1.page-title::before {
  background-color: #fff;
}

.login-page {
  font-family: "OpenSans-Regular", arial, sans-serif;
  background: #eee;
  padding: 57px 0;
}
@media (max-width: 1199.98px) {
  .login-page {
    max-width: 100%;
    padding: 20px 0;
  }
}
.login-page .remember-me,
.login-page .forgot-password a {
  font-size: 14px;
  color: #1e1e2c;
  text-decoration: underline;
}
.login-page .remember-me .custom-control-label {
  text-decoration: underline;
}
.login-page .forgot-password a:hover {
  color: #1e1e2c;
}
.login-page .password-div {
  position: relative;
}
.login-page .password-div .secureView {
  position: absolute;
  top: 40px;
  right: 15px;
}
.login-page .password-div .secureView .fa {
  font-size: 18px;
  color: black;
  cursor: pointer;
}
@media (min-width: 769px) {
  .login-page .login-section {
    border-right: 1px solid #d9d9d9;
  }
}
.login-page .login-section .primary-login-head {
  color: black;
}
.login-page .login-section .login-submit {
  background-color: #868ce0 !important;
  border-color: #868ce0;
}
.login-page .login-section .login-submit:focus, .login-page .login-section .login-submit:hover {
  box-shadow: none !important;
  opacity: 0.9;
}
.login-page .login-section .login-help-text {
  font-size: 10px;
}
.login-page .login-section .border-title {
  font-family: "OpenSans-Regular", arial, sans-serif;
  font-size: 20px;
  color: #707070;
  position: relative;
  text-align: center;
}
.login-page .login-section .border-title::before {
  content: "";
  width: 100%;
  border-bottom: 1px solid #cbcbcb;
  position: absolute;
  top: 50%;
  left: 0;
}
.login-page .login-section .border-title span {
  position: relative;
  padding: 0 10px;
  z-index: 2;
  color: black;
  font-size: 14px;
  font-weight: 700;
}
.login-page .login-section .social-login .social-login-primary-head {
  color: #1e1e2c;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: "OpenSans-Regular", arial, sans-serif;
}
.login-page .login-section .social-login .social-login-label {
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
}
.login-page .login-section .social-login .social-login-label .social-login-label-text {
  background: #fff;
  position: relative;
  top: -2px;
}
.login-page .login-section .social-login .login-oauth .form-group {
  display: inline-block;
}
.login-page .login-section .social-login .login-oauth .form-group .login-oauth-btn {
  display: inline-block;
  width: 100%;
  height: 45px;
  background-repeat: no-repeat;
  background-color: white;
  box-shadow: none;
  background-size: contain;
  border: 1px solid transparent;
}
.login-page .login-section .social-login .login-oauth .form-group .oauth-facebook {
  background-position: center;
  background-image: url("../images/facebook-login.svg");
}
.login-page .login-section .social-login .login-oauth .form-group .oauth-twitter {
  background-position: center;
  background-image: url("../images/twitter.svg");
}
.login-page .login-section .social-login .login-oauth .form-group .oauth-twitter:hover {
  filter: brightness(115%);
}
.login-page .login-section .social-login .login-oauth .form-group .oauth-google {
  background-position: center;
  background-image: url("../images/google-login.svg");
  width: 100%;
  height: 45px;
  background-size: contain;
}
.login-page .login-section .guest-checkout-email .login-guest-user {
  background-color: #868ce0 !important;
  border-color: #868ce0 !important;
  color: white;
  border-radius: 0;
  font-weight: 700;
  height: 43px;
  font-size: 16px;
  font-family: "OpenSans-Bold", arial, sans-serif;
  line-height: 30px;
}
.login-page .login-section .guest-checkout-email .login-guest-user:focus, .login-page .login-section .guest-checkout-email .login-guest-user:hover {
  box-shadow: none !important;
  opacity: 0.9;
}
.login-page .register-section .primary-reg-head {
  color: black;
  text-transform: uppercase;
}
.login-page .register-section .password-constraints {
  font-size: 13px;
  line-height: 1.6em;
}
.login-page .register-section .password-constraints .password-guidelines-constraints {
  color: #ffa603;
}
.login-page .register-section .privacy-policy {
  font-size: 10px;
}
.login-page .register-section .privacy-policy .privacy-policy-btn {
  color: #1e1e2c;
}
.login-page .register-section .privacy-policy .privacy-policy-btn:hover {
  color: #1e1e2c;
}
.login-page .register-section .privacy-policy a {
  text-decoration: underline;
}
.login-page .register-section .custom-control-label {
  font-size: 13px;
  font-weight: 400;
  margin-left: 15px;
}
.login-page .register-section ._email_me {
  font-size: 14px !important;
}
.login-page .register-section input[type=checkbox] {
  width: 25px;
  height: 25px;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
}
.login-page .checkout-page .login-guest-user {
  display: block;
}
.login-page .primary-login-reg-head {
  font-family: "OpenSans-Bold", arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
}
.login-page .login-reg-head {
  font-family: "OpenSans-Bold", arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  border-radius: 0;
  height: 43px;
}
.login-page .form-group label.form-control-label {
  font-size: 14px;
  font-weight: 400;
}
.login-page .form-group input.form-control {
  width: 100%;
  height: 43px;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  box-shadow: none;
  border-radius: 0;
}
.login-page .form-group input.form-control:focus {
  color: #0083df;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
}
.login-page .form-group .custom-control-input {
  z-index: unset;
  width: auto;
  opacity: unset;
  margin-top: 0;
}
.login-page .form-group .custom-control-label::before,
.login-page .form-group .custom-control-label::after {
  display: none;
}
.login-page .form-group.required .form-control-label::after {
  content: "*";
  color: #f00;
}
.login-page .form-group.required .form-control-label::before {
  display: none;
}
.login-page .login-div {
  border-radius: 10px;
  background: white;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09);
  width: 918px;
  padding: 40px 15px 50px 15px;
}
@media (max-width: 991.98px) {
  .login-page .login-div {
    width: 100%;
    padding: 0 15px 30px 15px;
  }
}
.login-page .check-container {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 2px;
}
.login-page .check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.login-page .check-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
}
.login-page ._canada_checkbox {
  font-size: 13px !important;
  margin-bottom: 35px;
}
.login-page .check-container input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid #bcbcbc;
}
.login-page .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
.login-page .check-container input:checked ~ .checkmark::after {
  display: block;
}
.login-page .check-container .checkmark::after {
  left: 9px;
  top: 4px;
  width: 5px;
  height: 13px;
  border: solid #27a21b;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media (max-width: 991.98px) {
  .login-page .login-part {
    margin: 0 15px;
  }
  .login-page .primary-login-reg-head {
    margin-top: 30px;
  }
}

form[name=change-password-form] .btn-outline-primary {
  background-color: #ababab;
  border-color: #ababab;
  color: #333;
}

.login-form {
  padding-right: 2rem;
}
@media (max-width: 991.98px) {
  .login-form {
    padding-right: 1rem;
  }
}

.register-section {
  padding-left: 2rem;
}
@media (max-width: 991.98px) {
  .register-section {
    padding-left: 1rem;
  }
}

.modal-dialog.login-dialog {
  max-width: 920px;
}
@media (max-width: 991.98px) {
  .modal-dialog.login-dialog {
    max-width: 95% !important;
  }
}
@media (max-width: 768.98px) {
  .modal-dialog.login-dialog .register-section {
    padding-top: 30px;
  }
}

.reset-password-form {
  font-size: 13px;
}
.reset-password-form #submitEmailButton {
  font-size: 14px;
}
.reset-password-form .red-msg {
  color: #d62a2a;
  font-weight: 700;
  font-family: "OpenSans-Bold", arial, sans-serif;
  font-size: 15px;
}

button.create-submit.login-reg-head {
  background-color: #868ce0 !important;
  border-color: #868ce0 !important;
}
button.create-submit.login-reg-head:focus, button.create-submit.login-reg-head:hover {
  background-color: #868ce0 !important;
  border-color: #868ce0 !important;
  box-shadow: none !important;
  opacity: 0.9;
}

/** --login color styles-- **/
.CRO_generic_color .login-page .login-section .primary-login-head,
.CRO_generic_color .login-page .register-section .primary-reg-head {
  color: #333;
}
.CRO_generic_color h1.page-title {
  color: #333;
}
.CRO_generic_color .login-page .login-section .login-submit {
  background-color: #333 !important;
  border-color: #333;
}
.CRO_generic_color .login-page .login-section .login-submit:focus, .CRO_generic_color .login-page .login-section .login-submit:hover {
  box-shadow: none !important;
  opacity: 0.9;
}
.CRO_generic_color button.create-submit.login-reg-head {
  background-color: #333 !important;
  border-color: #333 !important;
}
.CRO_generic_color button.create-submit.login-reg-head:focus {
  background-color: #333 !important;
  border-color: #333 !important;
  box-shadow: none !important;
}

.form-group.required .form-control-label::before {
  content: "" !important;
}
.form-group.required .form-control-label::after {
  content: "*" !important;
  color: black !important;
  margin-left: -3px;
}

.login-dialog .login-page {
  background: transparent;
  padding: 20px 0;
}
.login-dialog .login-page .login-part {
  margin: 0;
}
.login-dialog .login-page .primary-login-reg-head {
  margin-top: 0;
}
.login-dialog .login-div {
  box-shadow: none;
  padding: 0;
  width: 100%;
  margin-top: -35px;
  max-width: 100% !important;
}
.login-dialog .modal-body {
  padding: 10px;
}
.login-dialog .modal-header {
  border-bottom: 0;
}
.login-dialog .modal-footer {
  border-top: 0;
}

#loginModal {
  padding-right: 0 !important;
}