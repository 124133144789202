@import "~@sfra/scss/login";
@import "variables";
@import "base/fonts";

h1.page-title {
    color: $lc-red;
    font-size: 30px;
    background: $white;
    font-family: $opensans-light;
    left: unset;
    overflow: hidden;

    &::before {
        background-color: $white;
    }
}

.login-page {
    font-family: $opensans-regular;
    background: #eee;
    padding: 57px 0;

    @include media-breakpoint-down(lg) {
        max-width: 100%;
        padding: 20px 0;
    }

    .remember-me,
    .forgot-password a {
        font-size: 14px;
        color: $dark-gunmetal;
        text-decoration: underline;
    }

    .remember-me {
        .custom-control-label {
            text-decoration: underline;
        }
    }

    .forgot-password a {
        &:hover {
            color: $dark-gunmetal;
        }
    }

    .password-div {
        position: relative;

        .secureView {
            position: absolute;
            top: 40px;
            right: 15px;

            .fa {
                font-size: 18px;
                color: black;
                cursor: pointer;
            }
        }
    }

    .login-section {
        @include media-breakpoint-up(md) {
            border-right: 1px solid #d9d9d9;
        }

        .primary-login-head {
            color: black;
        }

        .login-submit {
            background-color: $picton-blue !important;
            border-color: $picton-blue;

            &:focus,
            &:hover {
                box-shadow: none !important;
                opacity: 0.9;
            }
        }

        .login-help-text {
            font-size: 10px;
        }

        .border-title {
            font-family: $opensans-regular;
            font-size: 20px;
            color: #707070;
            position: relative;
            text-align: center;

            &::before {
                content: "";
                width: 100%;
                border-bottom: 1px solid #cbcbcb;
                position: absolute;
                top: 50%;
                left: 0;
            }

            span {
                position: relative;
                padding: 0 10px;
                z-index: 2;
                color: black;
                font-size: 14px;
                font-weight: $font-wt-bold;
            }
        }

        .social-login {
            .social-login-primary-head {
                color: $dark-gunmetal;
                margin-bottom: 15px;
                font-size: 18px;
                font-family: $opensans-regular;
            }

            .social-login-label {
                width: 100%;
                font-weight: $font-wt-bold;
                text-transform: uppercase;
                font-size: 18px;

                .social-login-label-text {
                    background: $white;
                    position: relative;
                    top: -2px;
                }
            }

            .login-oauth {
                .form-group {
                    display: inline-block;

                    .login-oauth-btn {
                        display: inline-block;
                        width: 100%;
                        height: 45px;
                        background-repeat: no-repeat;
                        background-color: white;
                        box-shadow: none;
                        background-size: contain;
                        border: 1px solid transparent;
                    }

                    .oauth-facebook {
                        background-position: center;
                        background-image: url("../images/facebook-login.svg");
                    }

                    .oauth-twitter {
                        background-position: center;
                        background-image: url("../images/twitter.svg");

                        &:hover {
                            filter: brightness(115%);
                        }
                    }

                    .oauth-google {
                        background-position: center;
                        background-image: url("../images/google-login.svg");
                        width: 100%;
                        height: 45px;
                        background-size: contain;
                    }
                }
            }
        }

        .guest-checkout-email {
            .login-guest-user {
                background-color: $picton-blue !important;
                border-color: $picton-blue !important;
                color: white;
                border-radius: 0;
                font-weight: $font-wt-bold;
                height: 43px;
                font-size: 16px;
                font-family: $opensans-bold;
                line-height: 30px;

                &:focus,
                &:hover {
                    box-shadow: none !important;
                    opacity: 0.9;
                }
            }
        }
    }

    .register-section {
        .primary-reg-head {
            color: black;
            text-transform: uppercase;
        }

        .password-constraints {
            font-size: 13px;
            line-height: 1.6em;

            .password-guidelines-constraints {
                color: #ffa603;
            }
        }

        .privacy-policy {
            font-size: 10px;

            .privacy-policy-btn {
                color: $dark-gunmetal;

                &:hover {
                    color: $dark-gunmetal;
                }
            }

            a {
                text-decoration: underline;
            }
        }

        .custom-control-label {
            font-size: 13px;
            font-weight: 400;
            margin-left: 15px;
        }

        ._email_me {
            font-size: 14px !important;
        }

        input[type="checkbox"] {
            width: 25px;
            height: 25px;
            border: 1px solid #bcbcbc;
            border-radius: 5px;
        }
    }

    .checkout-page {
        .login-guest-user {
            display: block;
        }
    }

    .primary-login-reg-head {
        font-family: $opensans-bold;
        font-weight: $font-wt-bold;
        font-size: 18px;
    }

    .login-reg-head {
        font-family: $opensans-bold;
        font-weight: $font-wt-bold;
        font-size: 16px;
        border-radius: 0;
        height: 43px;
    }

    .form-group {
        label.form-control-label {
            font-size: 14px;
            font-weight: $font-wt-regular;
        }

        input.form-control {
            width: 100%;
            height: 43px;
            padding: 6px 12px;
            font-size: 14px;
            border: 1px solid $grey3;
            box-shadow: none;
            border-radius: 0;

            &:focus {
                color: $navy-blue;
                box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
            }
        }

        .custom-control-input {
            z-index: unset;
            width: auto;
            opacity: unset;
            margin-top: 0;
        }

        .custom-control-label::before,
        .custom-control-label::after {
            display: none;
        }

        &.required .form-control-label::after {
            content: "*";
            color: $red;
        }

        &.required .form-control-label::before {
            display: none;
        }
    }

    .login-div {
        border-radius: 10px;
        background: white;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09);
        width: 918px;
        padding: 40px 15px 50px 15px;
        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 0 15px 30px 15px;
        }
    }

    .check-container {
        display: block;
        position: relative;
        padding-left: 40px;
        margin-bottom: 25px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-top: 2px;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #fff;
            border: 1px solid #bcbcbc;
            border-radius: 5px;
        }
    }

    ._canada_checkbox {
        font-size: 13px !important;
        margin-bottom: 35px;
    }

    .check-container input:checked ~ .checkmark {
        background-color: #fff;
        border: 1px solid #bcbcbc;
    }

    .checkmark::after {
        content: "";
        position: absolute;
        display: none;
    }

    .check-container input:checked ~ .checkmark::after {
        display: block;
    }

    .check-container .checkmark::after {
        left: 9px;
        top: 4px;
        width: 5px;
        height: 13px;
        border: solid #27a21b;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    @include media-breakpoint-down(md) {
        .login-part {
            margin: 0 15px;
        }

        .primary-login-reg-head {
            margin-top: 30px;
        }
    }
}

form[name="change-password-form"] {
    .btn-outline-primary {
        background-color: $dark-gray2;
        border-color: $dark-gray2;
        color: $night-rider;
    }
}

.login-form {
    padding-right: 2rem;

    @include media-breakpoint-down(md) {
        padding-right: 1rem;
    }
}

.register-section {
    padding-left: 2rem;

    @include media-breakpoint-down(md) {
        padding-left: 1rem;
    }
}

.modal-dialog {
    &.login-dialog {
        max-width: 920px;

        @include media-breakpoint-down(md) {
            max-width: 95% !important;
        }

        @include media-breakpoint-down(sm) {
            .register-section {
                padding-top: 30px;
            }
        }
    }
}

// Reset Password
.reset-password-form {
    font-size: 13px;

    #submitEmailButton {
        font-size: 14px;
    }

    .red-msg {
        color: $red-reset-psd;
        font-weight: $font-wt-bold;
        font-family: $opensans-bold;
        font-size: 15px;
    }
}

button.create-submit.login-reg-head {
    background-color: $picton-blue !important;
    border-color: $picton-blue !important;

    &:focus,
    &:hover {
        background-color: $picton-blue !important;
        border-color: $picton-blue !important;
        box-shadow: none !important;
        opacity: 0.9;
    }
}

/** --login color styles-- **/
.CRO_generic_color {
    .login-page .login-section .primary-login-head,
    .login-page .register-section .primary-reg-head {
        color: $primarybtn-color;
    }

    h1.page-title {
        color: $primarybtn-color;
    }

    .login-page {
        .login-section {
            .login-submit {
                background-color: $primarybtn-color !important;
                border-color: $primarybtn-color;

                &:focus,
                &:hover {
                    box-shadow: none !important;
                    opacity: 0.9;
                }
            }
        }
    }

    button.create-submit.login-reg-head {
        background-color: $primarybtn-color !important;
        border-color: $primarybtn-color !important;

        &:focus {
            background-color: $primarybtn-color !important;
            border-color: $primarybtn-color !important;
            box-shadow: none !important;
        }
    }
}

.form-group {
    &.required .form-control-label::before {
        content: "" !important;
    }

    &.required .form-control-label::after {
        content: "*" !important;
        color: black !important;
        margin-left: -3px;
    }
}

.login-dialog {
    .login-page {
        background: transparent;
        padding: 20px 0;

        .login-part {
            margin: 0;
        }

        .primary-login-reg-head {
            margin-top: 0;
        }
    }

    .login-div {
        box-shadow: none;
        padding: 0;
        width: 100%;
        margin-top: -35px;
        max-width: 100% !important;
    }

    .modal-body {
        padding: 10px;
    }

    .modal-header {
        border-bottom: 0;
    }

    .modal-footer {
        border-top: 0;
    }
}

#loginModal {
    padding-right: 0 !important;
}
